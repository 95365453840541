export class Cst {
  public static WebServices = {
    services: {
        getRetailers : '/api/getRetailers',
        postDiagnostic: '/api/postDiagnostic',
        login: '/api/Utilisateurs/Login',
        users: '/api/Utilisateurs',
        retailers: '/api/Detaillants',
        retailersByCode: '/api/Detaillants/ByCode',
        equipmentList: '/api/Materiel/GetAllWithLastHistory',
        diagnosticListXlast: '/api/Diag/GetDiagHistoryByIdDetaillant',
        diagnosticList: '/api/Diag/GetNewDiagQuestionsByIdDetaillant',
        saveDiagDuJour: '/api/Diag/PostNewAnsweredDiag',
        photos: '/api/photos',
        diag: '/api/diag',
        libdiagx: '/api/Diag/GetLastDiagsByIdDetaillant',
        diagDuJourInit: '/api/Utilisateurs',
        diagDuJourEnd : 'GetHistory',
        flag: '/api/Flag',
        recupCampagne: '/api/Campagnes/GetCurrent',
        typeDiag: '/api/TypeDiags',
        typeCiblage: '/api/TypeCiblage',
        moyens: '/api/Moyens',
        SAFamilles: '/api/SAFamilles/GetAllwithSAMoyens',
        SADiagnostic: '/api/SADiagnostics/GetLast',
        SANiveauUtilisation: '/api/SANiveauxUtilisations/GetAll',
        SANiveauSatisfaction: '/api/SANiveauxSatisfactions/GetAll',
        SASaveDiagDuJour: '/api/SADiagnostics/PostNewSADiagnostic',
        SAResponsesList: '/api/SAReponses/GetLastByIdMoyens',
        statRetailer: '/api/Stat/Detaillant',
        SAMoyenHasResponse: '/api/SAReponses/HasLastMoyens',
        SAMoyenLastResponses: '/api/SAReponses/LastWithMoyens',
        SAMoyenLastResponsesHistory: '/api/SAReponses/Last',
    },
    errors: {
      wsError:
    "Erreur lors de l'enregistrement, veuillez réessayer. Si le problème persiste contactez le CCRV.",
    },
};
public static Message = {
    infoConnexion: {
        title: '',
        message: 'Merci de contacter le CCRV au 04.42.34.28.12'
    },
    attente: {
        connexion: 'Connexion en cours...',
        verificationMDP : 'Vérification des identifiants...',
        uploadImage : `Veuillez patienter pendant l'upload de votre fichier`
    },
    encours: {
        title: 'En cours de dev',
        message: 'La fonctionnalité est en cours de développement et sera disponible prochainement'
    },
    identifiantIncorrect: {
        title: 'Identifiant incorrect',
        message: 'Le nom d’utilisateur ou le mot de passe est incorrect'
    },
    erreurTechnique: {
        title: 'Erreur technique',
        message: 'Une erreur technique s`est produite.'
    },
    erreurWS: {
        title: 'Erreur technique',
        message: 'Erreur du Webservice',
        messageUtilisateur: 'Erreur lors de l’enregistrement, veuillez réessayer. Si le problème persiste contactez le CCRV.'
    },
    saisieObligatoire : 'Veuillez renseigner vos informations de connexions.',
    DiagnosticEnregistre : 'Diagnostic enregistré avec succès',
    saisieIncomplete: 'La saisie est incomplète',
    erreurCampagneAbsente : 'Impossible de trouver la campagne en cours.'

};
public static retailers = {
    messages: {
        noresults: 'aucun détaillant trouvé avec ces informations.',
        errorsearchstring: 'vous devez saisir au moins 3 caractères pour lancer une recherche.',
        waiting: 'recherche des détaillants ...'
    }
};
public static typereponse = {
    monoselection: 1,
    multiselection: 2,
    saisie: 3
};
public static idTypeDiag = 4;
public static from = {
    diagx: 'DiagX',
    equipment: 'Equipment',
    diagjour: 'DiagJour',
    diag: 'Diag',
    retaillist: 'retailerresult',
    moyens: 'Moyens',
    moyensList: 'Moyens-List'
};
public static typeActivite = {
    storeCheck360: {id:'1',label: 'Storecheck 360°'},
    suiviDesActivations: {id:'2', label: 'Suivi des moyens promotionnels'}
}
}
