import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonButton, IonContent, NavParams, ModalController } from '@ionic/angular/standalone';

@Component({
  selector: 'app-modale',
  templateUrl: './modale.component.html',
  styleUrls: ['./modale.component.scss'],
  imports: [CommonModule, FormsModule, IonContent, IonButton],
  standalone: true
})
export class ModaleComponent  implements OnInit {

  title = '';
  message = '';
  constructor(private navParams: NavParams,
              private modalCtrl: ModalController) { }

  ngOnInit() {
    this.title = this.navParams.get('title');
    this.message = this.navParams.get('message');
  }

  submit() {
    this.modalCtrl.dismiss({res: 'ma valeur de retour'});
  }

}

