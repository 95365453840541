import { TypeDiag } from './typeDiag';
import { TypeCiblage } from './typeCiblage';

export class GlobalSetting {
  // tslint:disable-next-line: variable-name
  private _idDetaillant: number = 0;
  // tslint:disable-next-line: variable-name
  private _codeDetaillant: number = 0;
  // tslint:disable-next-line: variable-name
  private _nomDetaillant: string = '';
  // tslint:disable-next-line: variable-name
  private _prenomDetaillant: string = '';
  // tslint:disable-next-line: variable-name
  private _materialIDList: number[] = [];
  // tslint:disable-next-line: variable-name
  private _idCampagne: number = 0;
  // tslint:disable-next-line: variable-name
  private _typeDiags: number[] = [];
  // tslint:disable-next-line: variable-name
  private _activityType: string = '0';
  // tslint:disable-next-line: variable-name
  private _idPdv: number = 0;
  // tslint:disable-next-line: variable-name
  private _typeCiblages: number[] = [];

  constructor() {}

  get idDetaillant(): number {
    return this._idDetaillant;
  }
  set idDetaillant(value: number) {
    this._idDetaillant = value;
  }
  get codeDetaillant(): number {
    return this._codeDetaillant;
  }
  set codeDetaillant(value: number) {
    this._codeDetaillant = value;
  }
  get nomDetaillant(): string {
    return this._nomDetaillant;
  }
  set nomDetaillant(value: string) {
    this._nomDetaillant = value;
  }
  get prenomDetaillant(): string {
    return this._prenomDetaillant;
  }
  set prenomDetaillant(value: string) {
    this._prenomDetaillant = value;
  }
  get materialIDList(): number[] {
    return this._materialIDList;
  }
  set materialIDList(value: number[]) {
    this._materialIDList = value;
  }
  get idCampagne(): number {
    return this._idCampagne;
  }
  set idCampagne(value: number) {
    this._idCampagne = value;
  }

  get typeDiags(): number[] {
    return this._typeDiags;
  }
  set typeDiags(value: number[]) {
    this._typeDiags = value;
  }

  get typeCiblages(): number[] {
    return this._typeCiblages;
  }
  set typeCiblages(value: number[]) {
    this._typeCiblages = value;
  }

  get activityType(): string {
    return this._activityType;
  }
  set activityType(value: string) {
    this._activityType = value;
  }

  get idPdv(): number {
    return this._idPdv;
  }
  set idPdv(value: number) {
    this._idPdv = value;
  }
}
