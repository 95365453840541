import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, SERVER_URL } from '../../environments/environment';
import { catchError, firstValueFrom, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ApiHelperService {
  // tslint:disable-next-line: variable-name
  private _url: string;
  constructor(private http: HttpClient) {
    this._url = SERVER_URL;
  }
  // Manage HTTP Quesries
   requestApi({
     action,
     method = 'GET',
     datas = {},
     httpHeader = {},
   }: {
     action: string;
     method?: string;
     datas?: any;
     httpHeader?: any;
   }): Promise<any> {
     const methodWanted = method.toLowerCase();
     const urlToUse = this.url + action;
     const httpOptions =
       httpHeader !== undefined
         ? httpHeader
         : {
             headers: new HttpHeaders({
               'Content-Type': 'application/json',
             }),
           };
     let req = null;
     switch (methodWanted) {
       case 'post':
         req = this.http.post(urlToUse, datas, httpOptions);
         break;
       case 'put':
         req = this.http.put(urlToUse, datas, httpOptions);
         break;
       case 'delete':
         req = this.http.delete(urlToUse, httpOptions);
         break;
       default:
        let module = '';
          if(datas){
            module = '?' + datas;
          }
         req = this.http.get(urlToUse + module, httpOptions);
         break;
     }
     return firstValueFrom(req); // Il renvoie un observable que l'on convertit en promesse
   }
  get url(): string {
    return this._url;
  }
}
