import { Component } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, filter } from 'rxjs';
import { GlobalsettingService } from './services/globalsetting.service';
import { CommonModule } from '@angular/common';
import { authCodeFlowConfig } from './auth.config';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, CommonModule],
})
export class AppComponent {
  isPopUpMode$: Observable<boolean> = new Observable<boolean>();
  constructor(private oauthService: OAuthService,
    private globalSettingService: GlobalsettingService,
    private authService: AuthService) {
      this.oauthService.configure(authCodeFlowConfig);
      this.oauthService.preserveRequestedRoute = true;
      this.oauthService.clearHashAfterLogin = true;
      this.authService.runInitialLoginSequence();
      // this.oauthService.setupAutomaticSilentRefresh();

    this.isPopUpMode$ = this.globalSettingService.isPopUpMode$.asObservable();
  }
}
