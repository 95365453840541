import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  throwError,
  of,
  BehaviorSubject,
  timer,
  isObservable,
} from 'rxjs';
import { catchError, delay, mergeMap, retry } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

const getErrorMessage = (maxRetry: number) =>
  `${maxRetry} echecs de communication au server.`;
const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_BACKOFF = 1000;

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  authExpired: BehaviorSubject<boolean>;

  constructor(
    public oidcSecurityService: OAuthService,
    private authService: AuthService,
    private router: Router
  ) {
    this.authExpired = new BehaviorSubject<boolean>(false);
  }

  onAuthExpired(): Observable<boolean> {
    return this.authExpired.asObservable();
  }

  retryWithBackoff(
    delayMS: number,
    maxRetry = DEFAULT_MAX_RETRIES,
    backoffMs = DEFAULT_BACKOFF
  ) {
    let retries = maxRetry;

    return (src: Observable<any>) =>
      src.pipe(
        retry({
          delay: (errors: Observable<any>) => {
            this.authExpired.next(true);
            if (isObservable(errors)) {
              return errors.pipe(
                mergeMap((error: any) => {
                  if (
                    error.status != 400 &&
                    error.status != 401 &&
                    error.status != 403
                  ) {
                    if (retries === maxRetry) {
                      this.authExpired.next(true);
                    }
                    if (retries-- > 0) {
                      const backoffTime =
                        delayMS + (maxRetry - retries) * backoffMs;
                      return timer(backoffTime);
                    }
                  }
                  return throwError(() => new Error(error));
                })
              );
            } else {
              return throwError(() => errors);
            }
          },
        })
      );
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.oidcSecurityService.getAccessToken();
    let cloneOpts = {};

    if (token && token != null && token != undefined && token != '') {
      cloneOpts = {
        setHeaders: { Authorization: 'Bearer ' + token },
      };
    }

    // const req = request.clone({
    //   withCredentials: true
    // });
    const req = request.clone(cloneOpts);
    //console.log(request.url);
    return next.handle(req).pipe(
      this.retryWithBackoff(1000),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Erreur client : ${error.error.message}`;
        } else if (error.status == 0) {
          errorMsg = `Impossible d'établir une connexion réseau avec le serveur.`;
        } else {
          switch (error.status) {
            case 401:
            case 403:
              this.authService.login();
              errorMsg = `Vous devez vous identifiez pour voir la page`;
              this.retryWithBackoff(1000);
              //this.router.navigate(['login']);
              break;
            default:
              errorMsg = '';
          }
        }
        if (errorMsg !== '') {
          return throwError(() => new Error(errorMsg));
        } else {
          return throwError(() => error.error);
        }
      })
    );
  }
}
