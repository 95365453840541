import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { filter, switchMap, tap } from 'rxjs';

export const authOidcGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  return authService.isDoneLoading$.pipe(
    filter(isDone => isDone),
    switchMap(_ => authService.isAuthenticated$),
    tap(isAuthenticated => isAuthenticated || authService.login(state.url)),
  );
};

