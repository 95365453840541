import { AuthConfig } from 'angular-oauth2-oidc';
import { environment, AUTH_REDIRECT_UI } from 'src/environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: 'https://csam-sso.integ.lfdj.com/IdPOAuth2/IDIAG_INT7',
  redirectUri: AUTH_REDIRECT_UI, //'https://dev-idiag.lafdp.com',//'https://idiag.test.groupe-isia.com',
  clientId: 'C1A7C0B5BF3951F2',
  responseType: 'code',
  scope: 'openid offline_access',
  showDebugInformation: true,
  strictDiscoveryDocumentValidation: false,
  // disable_iat_offset_validation: true,
  useSilentRefresh: true,
	silentRefreshRedirectUri: window.location.origin + '/assets/silent-renew.html',
};
