<ion-content class="ion-no-padding">
  <div class="modal-container">
    <div>
      <p class="modal-content">{{ title }}</p>
    </div>
    <div>
      <p class="modal-content">
        {{ message }}
      </p>
    </div>
    <div>
      <ion-button text-center fill="clear" (click)="submit()" class="button-std button-std-theme">Ok</ion-button>
    </div>
  </div>
</ion-content>
