import { Cst } from './../shared/cst.ts';
import { ApiHelperService } from './api-helper.service';
import { GlobalSetting } from './../shared/models/globalsetting';
import { Injectable } from '@angular/core';
import { TypeDiag } from '../shared/models/typeDiag';
import { ToolsService } from '../services/tools.service';
import { TypeCiblage } from '../shared/models/typeCiblage';
import { DataService } from './data.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalsettingService {
  public dataReceived$ = new BehaviorSubject<boolean>(false);
  public currentDiagTotal$ = new BehaviorSubject<number>(0);
  public currentDiagAnswered$ = new BehaviorSubject<number>(0);
  public isPopUpMode$ = new BehaviorSubject<boolean>(false);
  public showDiagProgress$ = new BehaviorSubject<boolean>(false);
  public displayDateDiag$ = new BehaviorSubject<boolean>(false);
  public currentDiagId$ = new BehaviorSubject<number>(-1);

  private setting: GlobalSetting = new GlobalSetting();
  constructor(
    private apiHelper: ApiHelperService,
    private tools: ToolsService,
    private dataService: DataService
  ) { }

  setGlobalSetting(idDetaillant: number, codeDetaillant: number, nomDetaillant: string, prenomDetaillant: string, typeDiags: number[], idPdv: number, typeCiblage: number[]){
    if (this.setting === undefined) {
      this.setting = new GlobalSetting();
    }
    this.setting.idDetaillant = idDetaillant;
    this.setting.codeDetaillant = codeDetaillant;
    this.setting.nomDetaillant = nomDetaillant;
    this.setting.prenomDetaillant = prenomDetaillant;
    this.setting.idPdv = idPdv;
    this.setting.typeDiags = typeDiags;
    this.setting.typeCiblages = typeCiblage;
    this.dataReceived$.next(true);
  }

  getActivityType(): string {
    return this.setting.activityType;
  }

  setActivityType(type: string) {
    this.setting.activityType = type;
  }

  getMaterialList(): number[] {
    return this.setting.materialIDList;
  }
  getIdDetaillant(): number {
    return this.setting.idDetaillant;
  }
  setIdDetaillant(id: number) {
    this.setting.idDetaillant = id;
  }
  getCodeDetaillant(): number {
    return this.setting.codeDetaillant;
  }
  setCodeDetaillant(code: number) {
    this.setting.codeDetaillant = code;
  }
  getNomDetaillant(): string {
    return this.setting.nomDetaillant;
  }
  getPrenomDetaillant(): string {
    return this.setting.prenomDetaillant;
  }
  getCampainId(): number {
    return this.setting.idCampagne;
  }
  setMaterialList(value: number[]= []){
    if (this.setting === undefined) {
      this.setting = new GlobalSetting();
    }
    this.setting.materialIDList = value;
  }
  setCampainId(value: number) {
    if (this.setting === undefined) {
      this.setting = new GlobalSetting();
    }
    this.setting.idCampagne = value;
  }
  retrieveCampainId() {
    let idCampagne = 0;
    const typdiag = Cst.idTypeDiag;
    return this.apiHelper.requestApi({
      action: Cst.WebServices.services.recupCampagne,
      method: 'get',
      datas: `idTypeDiag=${typdiag}`
    }).then((result) => {
      idCampagne = result.idCampagne;
      return Promise.resolve(idCampagne);
    }).catch( err => {
      this.tools.presentToast({message: err});
      return Promise.reject(err);
    });

  }

  getDetaillantTypeDiags() {
    return this.setting.typeDiags;
  }

  setDetaillantTypeDiags(newTypes: number[]) {
    this.setting.typeDiags = newTypes;
  }

  getDetaillantTypeDiagsIDs() {
    return this.setting.typeDiags;
  }

  getDetaillantTypeCiblagesIDs() {
    return this.setting.typeCiblages;
  }

  getDetaillantTypeCiblageLib() {
    let lib: string[] = [];
    this.setting.typeCiblages.forEach(item => {
      let ciblage = this.dataService.typeCiblages.find(c => c.idTypeCiblage == item)
      if(ciblage){
        lib.push(ciblage.nom);
      }
    });
    return lib;
  }

  getDetaillantCiblages() {
    return this.setting.typeCiblages;
  }

  getidPointDeVente() {
    return this.setting.idPdv;
  }
}
