
import { ApiHelperService } from './api-helper.service';
import { Injectable } from '@angular/core';
import { environment, SERVER_URL } from '../../environments/environment';
import { ClassUser } from '../shared/models/user';
import { Storage } from '@ionic/storage';
import { TypeDiag } from '../shared/models/typeDiag';
import { Cst } from '../shared/cst.ts';
import { ToolsService } from './tools.service';
import { TypeCiblage } from '../shared/models/typeCiblage';
import { Flag } from '../shared/models/flag';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  /**
   * Ce service sera utilisé pour tout ce qui touche aux appels webservice d'envoi et réception des données
   */
  constructor(
    private api: ApiHelperService,
    private storage: Storage,
    private tools: ToolsService
  ) { }

  public typeDiags: TypeDiag[] = [];
  public typeCiblages: TypeCiblage[] = [];
  public flags: Flag[] = [];

   public getUser({login, password}: {
     login: string;
     password: string;
   }): Promise<boolean> {
     // création de l'objet data qui sera envoyé au backend
     const param = {
       pseudo: login,
       mdp: password
     };

     return this.api
       .requestApi({ action: '/login', method: 'POST', datas: param })
       .then((res) => {
         if (!res) {
           throw new Error('Error login');
         }

         // traitement ici si besoin
         return res;
       })
       .catch((error) => {
         console.log(error);
        this.tools.presentToast({message: error});
         // traitement d'erreur

       });
   }

   getData(): ({utilisateur: ClassUser, pwd: string}) {
    const user = new ClassUser();
    let mdp = '';
    this.storage.get('nom').then((val: string) => {
        user.userName = val;
      });
    this.storage.get('mdp').then((val: string) => {
        mdp = val;
      });
    return ({utilisateur: user, pwd: mdp});
    }

    getStoredEmail() {
      return this.storage.get('email');
    }
    getStoredPwd() {
      return this.storage.get('mdp');
    }
    getStoredSecteur() {
      return this.storage.get('secteur');
    }
    getStoredSIDUser() {
      return this.storage.get('id');
    }

    save(user: ClassUser, pwd: string) {
      // set a key/value
      this.storage.set('nom', user.userName);
      this.storage.set('prenom', user.userFirstName);
      this.storage.set('secteur', user.idSecteur);
      this.storage.set('id', user.id);
      this.storage.set('email', user.userLoginAD);
      this.storage.set('nomcomplet', user.userFullName);
      this.storage.set('mdp', pwd);
      }

    getTypeDiagsWS() {
      return this.api.requestApi({
          action: `${Cst.WebServices.services.typeDiag}/GetAllActive` ,
          method: 'get',
          datas: null
        }).then(res => {
          this.typeDiags = [];
//          console.log(res);
          res.forEach((element: any) => {
            this.typeDiags.push(element) ;
          });
          return true;
        }).catch(error => {
          console.log(error);
          this.tools.presentToast({message: error});
          return false;
        });
    }

    getTypeDiagIds() {
      let id: number[] = [];
      this.typeDiags.forEach(element => {
        id.push(element.idTypeDiag);
      });
      return id;
    }

    getTypeCiblagesWS() {
      return this.api.requestApi({
          action: `${Cst.WebServices.services.typeCiblage}/GetAllActive` ,
          method: 'get',
          datas: null
        }).then(res => {
          this.typeCiblages = [];
          res.forEach((element: any) => {
            this.typeCiblages.push(element) ;
          });
          return true;
        }).catch(error => {
          console.log(error);
          this.tools.presentToast({message: error});
          return false;
        });
    }

    getFlagsWS(){
      return this.api.requestApi({
        action: `${Cst.WebServices.services.flag}`,
        method: 'get',
        datas: null
      }).then(res => {
        this.flags = [];
        res.forEach((element: any) => {
          this.flags.push(element)
        });
        return true;
      }).catch(error => {
        console.log(error);
          this.tools.presentToast({message: error});
          return false;
      })
    }
}

