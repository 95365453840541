import { Routes } from '@angular/router';
import { authOidcGuard } from './guards/auth-oidc.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'diagnostic/:retailerCode',
    loadComponent: () => import('./pages/tab-shell/tab-shell.page').then( m => m.TabShellPage),
    canActivate: [authOidcGuard],
    canActivateChild: [authOidcGuard],
    children: [
      {
        path: 'new',
        loadComponent: () => import('./pages/diagnostic/diagnostic.page').then( m => m.DiagnosticPage),
        data: {isPopUpMode: true}
      },
      {
        path: 'history/:idDiag',
        loadComponent: () => import('./pages/diagnostic-history/diagnostic-history.page').then( m => m.DiagnosticHistoryPage),
      },
      {
        path: 'statistic',
        loadComponent: () => import('./pages/statistic/statistic.page').then( m => m.StatisticPage),
      },
      {
        path: 'photos',
        loadComponent: () => import('./pages/photos/photos.page').then( m => m.PhotosPage),
      }
    ]
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'moyens/:retailerCode/new',
    loadComponent: () => import('./pages/moyens/moyens.page').then( m => m.MoyensPage),
    canActivate: [authOidcGuard],
    data: {isPopUpMode: true},
  },
  {
    path: 'moyens/:retailerCode',
    loadComponent: () => import('./pages/moyens-history/moyens-history.page').then( m => m.MoyensHistoryPage),
    canActivate: [authOidcGuard],
    data: {isPopUpMode: false},
  },

  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.page').then( m => m.HomePage),
    canActivate: [ ],
  }

];
