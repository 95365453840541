import { ReponseType } from './../shared/models/reponsetype';

import { Injectable } from '@angular/core';
import {
  ToastController,
  ModalController,
  AlertController,
} from '@ionic/angular/standalone';
import { ModaleComponent } from '../components/modale/modale.component';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  /**
   * Ce service sera utilisé pour toutes les petites fonction outils qu'on peut avoir besoin
   * ex : appels générique pour les modales de confirmation et/ou d'alerte
   */
  constructor(
    private toastController: ToastController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {}
  /**
   * Création d'un message toast
   * @param param0.message : message à afficher
   * @param param0.duration : durée d'afficahge du toast
   * @param param0.type : type de coloration du toast
   */
  async presentToast({
    message = '',
    duration = 4000,
    type = 'toast',
    cancelButton = false
  }: {
    message?: string;
    duration?: number;
    type?: string;
    cancelButton?: boolean
  }) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'top',
      cssClass: type,
      buttons: cancelButton ? ['Ok'] : []
      // header: ''
    });
    toast.present();
  }

  /**
   * Fonction qui permet la recherche d'une valeur dans un tableau JSON
   * @param _value : valeur cherchée
   * @param _myArray : variable contenant le tableau json
   * @param _rub : libellé de la rubrique dans laquelle chercher la valeur
   */
  // tslint:disable-next-line: variable-name
  arraySearch(_value: any, _myArray: any, _rub: any) {
    if (!_rub) {
      _rub = 'id';
    } // default search on "id" member
    for (let i = 0; i < _myArray.length; i++) {
      // tslint:disable-next-line: no-eval
      if (eval('_myArray[i].' + _rub) === _value) {
        return i;
      }
    }
    return -1;
  }

  async info(titre: string, msg: string) {
    const modal = await this.modalCtrl.create({
      component: ModaleComponent,
      componentProps: {
        title: titre,
        message: msg,
      },
      cssClass: 'modal',
    });

    modal.onDidDismiss().then((detail: any) => {
      if (detail.data) {
        //        console.log(detail.data);
      }
    });
    return await modal.present();
  }

  async presentDeleteCancel({
    title = 'Alert',
    msg = '',
    okCallback = () => {},
    cancelCallback = () => {},
  }) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      cssClass: 'alertModel',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            cancelCallback();
          },
        },
        {
          text: 'Supprimer',
          handler: () => {
            okCallback();
          },
        },
      ],
    });

    await alert.present();
  }

  /**
   * Compléter le nombre avec un 0 devant s'il est < 10
   */
  pad(s: any) {
    return s < 10 ? '0' + s : s;
  }

  typeDiagVisualImageFromID(repereID: any) {
    let ret: string;
    switch (repereID) {
      case 1:
        ret = './assets/imgs/repere/bolt-solid.svg';
        break;
      case 2:
        ret = './assets/imgs/repere/clock-regular.svg';
        break;
      case 3:
        ret = './assets/imgs/repere/futbol-solid.svg';
        break;
      case 4:
        ret = './assets/imgs/repere/hat-cowboy-solid.svg';
        break;
      case 5:
        ret = './assets/imgs/repere/rocket-solid.svg';
        break;
      case 6:
        ret = './assets/imgs/repere/star-solid.svg';
        break;
      case 7:
        ret = './assets/imgs/repere/sun-solid.svg';
        break;
      case 8:
        ret = './assets/imgs/repere/trophy-solid.svg';
        break;
      default:
        ret = './assets/imgs/repere/pixel.png';
        break;
    }
    return ret;
  }

  async presentOkConfirm({
    title = 'Alert',
    msg = '',
    okCallback = () => {},
    cancelCallback = () => {},
  }) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      cssClass: 'alertModel',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            cancelCallback();
          },
        },
        {
          text: 'Confirmer',
          handler: () => {
            okCallback();
          },
        },
      ],
    });

    await alert.present();
    return alert.onDidDismiss();
  }
}
