import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { PreloadAllModules, RouteReuseStrategy, provideRouter, withComponentInputBinding,  withPreloading } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { IonicStorageModule } from '@ionic/storage-angular'
import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { HttpInterceptorService } from './app/services/http-interceptor.service';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { authCodeFlowConfig } from './app/auth.config';
import 'zone.js';

defineCustomElements(window);
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withComponentInputBinding(), withPreloading(PreloadAllModules)),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideOAuthClient(),
    importProvidersFrom(
      IonicStorageModule.forRoot()
    ),
    { provide: HTTP_INTERCEPTORS, useExisting: HttpInterceptorService, multi: true }
  ],
});
