export class ClassUser {
  // tslint:disable-next-line: variable-name
  private  _id: number;
  // tslint:disable-next-line: variable-name
  private  _code: string;
  // tslint:disable-next-line: variable-name
  private  _userName: string;
  // tslint:disable-next-line: variable-name
  private  _userFirstName: string;
  // tslint:disable-next-line: variable-name
  private  _userFullName: string;
  // tslint:disable-next-line: variable-name
  private  _userLoginAD: string;
  // tslint:disable-next-line: variable-name
  private  _idSecteur: number;

  constructor()
  constructor(
              id?: number,
              code?: string,
              userName?: string,
              userFirstName?: string,
              userFullName?: string,
              userLoginAD?: string,
              idSecteur?: number
            ) {
    this._id = id ?? 0;
    this._code = code ?? '';
    this._userFirstName = userFirstName ?? '';
    this._userFullName = userFullName ?? '';
    this._userLoginAD = userLoginAD ?? '';
    this._userName = userName ?? '';
    this._idSecteur = idSecteur ?? 0;
  }

/* constructor() {}*/
  /* GETTERS - SETTERS */
  get id(): number {
      return this._id;
  }
  set id(value: number) {
      this._id = value;
  }
  get code(): string {
      return this._code;
  }
  set code(value: string) {
      this._code = value;
  }
  get userName(): string {
    return this._userName;
  }
  set userName(value: string) {
    this._userName = value;
  }
  get userFirstName(): string {
    return this._userFirstName;
  }
  set userFirstName(value: string) {
    this._userFirstName = value;
  }
  get userFullName(): string {
    return this._userFullName;
  }
  set userFullName(value: string) {
    this._userFullName = value;
  }
  get userLoginAD(): string {
    return this._userLoginAD;
  }
  set userLoginAD(value: string) {
    this._userLoginAD = value;
  }
  get idSecteur(): number {
    return this._idSecteur;
  }
  set idSecteur(value: number) {
    this._idSecteur = value;
  }

}
